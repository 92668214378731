import { post, get } from "./http";

/**
 * 登录
 * @param {*} map
 * @returns
 */
export const login = (map) => post("/api/login/login", map);

/**
 * 获取学校列表
 * @param {*} ketword
 * @returns
 */
export const getSchoolList = (ketword) => get("/api/school/list", ketword);

/**
 * 添加学校
 * @param {*} map
 * @returns
 */
export const addSchool = (map) => post("/api/school/add", map);

/**
 * 编辑学校
 * @param {*} map
 * @returns
 */
export const eidtSchool = (map) => post("/api/school/edit", map);

/**
 * 搜索用户
 * @param {*} map
 * @returns
 */
export const searchUser = (map) => get("/api/user/search", map);

/**
 * 修改用户信息
 * @param {*} map
 * @returns
 */
export const editUser = (map) => post("/api/user/edit", map);

/**
 * 获取轮播图列表
 * @param {*} schoolid
 * @returns
 */
export const getBannerList = (schoolid) => get("/api/banner/list", schoolid);

/**
 * 添加轮播
 * @param {*} map
 * @returns
 */
export const addBanner = (map) => post("/api/banner/add", map);

/**
 * 删除轮播
 * @param {*} id
 * @returns
 */
export const removeBanner = (id) => get("/api/banner/remove", id);

/**
 * 获取tabbar列表
 * @param {*} schoolid
 * @returns
 */
export const getTabbarList = (schoolid) => get("/api/tabbar/list", schoolid);

/**
 * 添加tabbar
 * @param {*} map
 * @returns
 */
export const addTabbar = (map) => post("/api/tabbar/add", map);

/**
 * 删除tabbar
 * @param {*} id
 * @returns
 */
export const removeTabbar = (id) => get("/api/tabbar/remove", id);

/**
 * 编辑tabbar
 * @param {*} map
 * @returns
 */
export const editTabbar = (map) => post("/api/tabbar/edit", map);

/**
 * 获取资讯列表
 * @param {*} map
 * @returns
 */
export const getNewsList = (map) => get("/api/news/list", map);

/**
 * 新增资讯
 * @param {*} map
 * @returns
 */
export const addNews = (map) => post("/api/news/add", map);

/**资讯
 * @param {*} map
 * @returns
 */
export const editNews = (map) => post("/api/news/edit", map);

/**
 * 删除资讯
 * @param {*} id
 * @returns
 */
export const removeNews = (id) => get("/api/news/remove", id);

/**
 * 文件上传
 * @param {*} id
 * @returns
 */
export const uploadFile = (map) => get("/api/common/upload", map);

/**
 * 获取资讯留言审核列表
 * @param {*} map
 * @returns
 */
export const getNewsReviewList = (map) => get("/api/news/reviewAdminList", map);

/**
 * 获取所有资讯留言审核列表
 * @param {*} map
 * @returns
 */
export const reviewAdminAllList = (map) =>
  get("/api/news/reviewAdminAllList", map);

/**
 * 审核资讯留言
 * @param {*} map
 * @returns
 */
export const reviewEdit = (map) => post("/api/news/reviewEdit", map);

/**
 * 获取帖子列表
 * @param {*} map
 * @returns
 */
export const getAdminPostList = (map) => get("/api/post/getAdminPostList", map);

/**
 * 编辑帖子
 * @param {*} map
 * @returns
 */
export const editPost = (map) => post("/api/post/edit", map);

/**
 * 获取帖子举报列表
 * @param {*} map
 * @returns
 */
export const getPostReportList = (map) =>
  get("/api/post/getPostReportList", map);

/**
 * 后台管理系统处理帖子举报
 * @param {*} map
 * @returns
 */
export const handlePostReport = (map) => get("/api/post/handlePostReport", map);

/**
 * 获取帖子评论列表(后台管理)
 * @param {*} map
 * @returns
 */
export const getPostReviewAdminList = (map) =>
  get("/api/post/getPostReviewAdminList", map);

/**
 * 获取所有帖子评论列表(后台管理)
 * @param {*} map
 * @returns
 */
export const getPostReviewAdminAllList = (map) =>
  get("/api/post/getPostReviewAdminAllList", map);

/**
 * 编辑帖子评论
 * @param {*} map
 * @returns
 */
export const reviewPostEdit = (map) => post("/api/post/reviewEdit", map);

/**
 * 是否需要审核
 * @param {*} map
 * @returns
 */
export const getIsCheck = () => get("/api/map/get?key=check");

/**
 * 修改是否需要审核
 * @param {*} map
 * @returns
 */
export const editIsCheck = (map) =>
  get("/api/map/edit", {
    key: "check",
    value: map,
  });

/**
 * 彻底删除帖子（后台管理系统）
 */
export const deletePost = (map) => get("/api/post/delete", map);

/**
 * 删除帖子评论
 */
export const reviewRemove = (map) => get("/api/post/reviewRemove", map);

/**
 * 删除资讯留言
 */
export const newsReviewRemove = (map) => get("/api/news/reviewRemove", map);

/**
 * 是否开启匿名
 * @param {*} map
 * @returns
 */
export const getIsAnonymity = () => get("/api/map/get?key=anonymity");

/**
 * 修改是否开启匿名
 * @param {*} map
 * @returns
 */
export const editIsAnonymity = (map) =>
  get("/api/map/edit", {
    key: "anonymity",
    value: map,
  });
