import axios from "axios";
import QS from "qs";
import store from "@/store/index";
import Vue from "vue";
import { baseURL } from "../utils/const";

// 设置域名
if (process.env.NODE_ENV === "development") {
  // 本地
  // axios.defaults.baseURL = 'http://wanzhuanwangluo.cn'
  axios.defaults.baseURL = baseURL;
} else if (process.env.NODE_ENV === "debug") {
  // 测试
  // axios.defaults.baseURL = 'http://wanzhuanwangluo.cn'
  axios.defaults.baseURL = baseURL;
} else if (process.env.NODE_ENV === "production") {
  // 生成
  // axios.defaults.baseURL = 'http://wanzhuanwangluo.cn'
  axios.defaults.baseURL = baseURL;
}

console.log(baseURL);

// 设置请求超时时间
axios.defaults.timeout = 15000;

// 设置post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    const token = store.state.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          console.log("401");
          break;
        case 403:
          console.log("403");
          break;
        case 404:
          console.log("404");
          break;
        case 500:
          console.log("500");
          break;
        default:
          console.log(error.response.data.msg);
      }
    }
    Vue.prototype.$message.error(error.response.data.msg);
    return Promise.reject(error.response);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
