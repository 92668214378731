import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import qs from "qs";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import hevueImgPreview from "hevue-img-preview";

Vue.use(ElementUI);
Vue.use(hevueImgPreview);

Vue.config.productionTip = false;
// 全局注册，使用方法为:this.$axios
Vue.prototype.$axios = axios;
// 全局注册，使用方法为:this.qs
Vue.prototype.qs = qs;

// 检查 localStorage 中是否有 token，如果有，则加载到 Vuex
const token = localStorage.getItem("token");
if (token) {
  store.commit("setToken", token); // 假设 Vuex 中有 setToken 的 mutation
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
