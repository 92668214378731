<template>
    <div class="page">
        <el-dialog :title="tabbarInfo === null ? '新增帖子类型' : '修改帖子类型'" :visible.sync="myShowDialog"
            :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
            <el-form>
                <el-form-item label="标题" :label-width="formLabelWidth">
                    <el-input v-model="title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth">
                    <el-input v-model="sort" placeholder="请输入排序（越大的往前）"></el-input>
                </el-form-item>
                <el-form-item label="路由" :label-width="formLabelWidth">
                    <el-input v-model="router" placeholder="请输入路由（post:1）post代表类型，1是id"></el-input>
                </el-form-item>
                <el-form-item label="icon" :label-width="formLabelWidth">
                    <el-upload class="avatar-uploader" :action="baseURL + '/api/common/upload'" :headers="uploadHeaders"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="canncelClick">取 消</el-button>
                <el-button type="primary" @click="addClick">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { addTabbar, editTabbar } from '@/http/api'
import { baseURL } from '@/utils/const'

export default {
    data() {
        return {
            baseURL: baseURL,
            myShowDialog: false,
            formLabelWidth: '120px',
            title: '',
            sort: '',
            router: '',
            imageUrl: '',
        };
    },
    props: {
        schoolid: {
            type: Number
        },
        showDialog: {
            type: Boolean
        },
        // 当这个不为null时，表示编辑
        tabbarInfo: {
            type: Object
        }
    },
    watch: {
        tabbarInfo(newVal, oldVal) {
            if (newVal !== null && newVal !== undefined) {
                this.title = newVal.title;
                this.sort = newVal.sort;
                this.router = newVal.router;
                this.imageUrl = newVal.icon;
            }
        },
        showDialog(newValue) {
            this.myShowDialog = newValue;
        }
    },
    computed: {
        // 动态获取 headers
        uploadHeaders() {
            return {
                Authorization: `Bearer ${this.$store.state.token}`, // 从 Vuex 中动态获取 Token
            };
        },
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
            return true;
        },
        addClick() {
            if (this.title === '') {
                return this.$message.error('标题不能为空')
            }
            if (this.imageUrl === '') {
                return this.$message.error('icon不能为空')
            }
            if (this.tabbarInfo === null || this.tabbarInfo === undefined) {
                addTabbar({
                    title: this.title,
                    icon: this.imageUrl,
                    sort: this.sort,
                    router: this.router,
                    schoolid: this.schoolid
                }).then(res => {
                    this.$emit('refreshList');
                    this.canncelClick()
                    this.$message.success('添加成功')
                })
            } else {
                editTabbar({
                    id: this.tabbarInfo.id,
                    title: this.title,
                    icon: this.imageUrl,
                    sort: this.sort,
                    router: this.router,
                }).then(res => {
                    this.$emit('refreshList');
                    this.canncelClick()
                    this.$emit('updateTabbarInfo');
                    this.$message.success('修改成功')
                })
            }
        },
        canncelClick() {
            this.title = ''
            this.sort = ''
            this.router = ''
            this.imageUrl = ''
            this.$emit('updateTabbarInfo');
            this.$emit('update:showDialog', false);
        }
    }
}
</script>
<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px !important;
    text-align: center;
}

.avatar {
    width: 78px;
    height: 78px;
    display: block;
}
</style>