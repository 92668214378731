<template>
    <div class="page">
        <el-dialog :title="newsInfo === null ? '新增资讯' : '修改资讯'" :visible.sync="myShowDialog"
            :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
            <el-form>
                <el-form-item label="标题" :label-width="formLabelWidth">
                    <el-input v-model="title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth">
                    <el-input v-model="sort" placeholder="请输入排序（越大越前）"></el-input>
                </el-form-item>
                <el-form-item v-if="isUrl === true" label="公众号链接" :label-width="formLabelWidth">
                    <el-input v-model="url" placeholder="请输入公众号链接"></el-input>
                </el-form-item>
                <div style="margin-bottom: 20px;" v-if="isUrl === false">
                    <ckeditor id="ckeditor" :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                </div>
                <el-form-item label="封面图片" :label-width="formLabelWidth">
                    <el-upload class="avatar-uploader" :action="baseURL + '/api/common/upload'" :headers="uploadHeaders"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="是否推荐" :label-width="formLabelWidth">
                    <el-switch v-model="isRecommend" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <el-form-item v-if="newsInfo === null" label="使用公众号链接" :label-width="formLabelWidth">
                    <el-switch v-model="isUrl" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <!-- <el-form-item v-if="newsInfo !== null" label="点赞数" :label-width="formLabelWidth">
                    <el-input v-model="likeNum" placeholder="是否推荐"></el-input>
                </el-form-item> -->
                <!-- <el-form-item v-if="newsInfo !== null" label="分享数" :label-width="formLabelWidth">
                    <el-input v-model="shareNum" placeholder="是否推荐"></el-input>
                </el-form-item> -->
                <el-form-item v-if="newsInfo !== null" label="阅读数" :label-width="formLabelWidth">
                    <el-input v-model="lookNum" placeholder="是否推荐"></el-input>
                </el-form-item>
                <!-- <el-form-item v-if="newsInfo !== null" label="留言数" :label-width="formLabelWidth">
                    <el-input v-model="reviewNum" placeholder="是否推荐"></el-input>
                </el-form-item> -->

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="canncelClick">取 消</el-button>
                <el-button type="primary" @click="addClick">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import { addNews, editNews } from '@/http/api'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn'
import UploadAdapter from '../../utils/upload-adapter'
import { baseURL } from '@/utils/const'

export default {
    components: {
        //局部注册方式
        ckeditor: CKEditor.component
    },
    data() {
        return {
            baseURL: baseURL,
            myShowDialog: false,
            formLabelWidth: '120px',
            title: '',
            url: '',
            isRecommend: false,
            isUrl: true,
            // likeNum: '',
            // shareNum: '',
            lookNum: '',
            // reviewNum: '',
            imageUrl: '',
            sort: '',
            editor: ClassicEditor,
            editorData: '',
            editorConfig: {
                placeholder: '请输入内容',
                language: 'zh-cn',
                //该方式本人没有使用过,使用ckfinder上传时要注意返回的格式
                //应该是{"uploaded":1,"url":"/"}
                //或者{"uploaded":true,"url":"/"}
                // ckfinder: {
                //     uploadUrl: '/',
                //     options: {
                //         resourceType: 'Images'
                //     }
                // },
                extraPlugins: [this.createUploadAdapterPlugin()],


            },
        };
    },
    props: {
        schoolid: {
            type: Number
        },
        showDialog: {
            type: Boolean
        },
        // 当这个不为null时，表示编辑
        newsInfo: {
            type: Object
        }
    },
    watch: {
        newsInfo(newVal, oldVal) {
            if (newVal !== null && newVal !== undefined) {
                this.title = newVal.title;
                this.url = newVal.url;
                this.isRecommend = newVal.isRecommend === 1 ? true : false;
                // this.likeNum = newVal.likeNum;
                // this.shareNum = newVal.shareNum;
                this.lookNum = newVal.lookNum;
                // this.reviewNum = newVal.reviewNum;
                this.imageUrl = newVal.image;
                this.editorData = newVal.content;
                this.sort = newVal.sort;
                this.isUrl = (newVal.content === null || newVal.content === undefined || newVal.content === '') ? true : false
            }
        },
        showDialog(newValue) {
            this.myShowDialog = newValue;
        },
    },
    computed: {
        // 动态获取 headers
        uploadHeaders() {
            return {
                Authorization: `Bearer ${this.$store.state.token}`, // 从 Vuex 中动态获取 Token
            };
        },
    },
    methods: {
        createUploadAdapterPlugin() {
            const editor = this.editor;
            return function (editor) {
                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new UploadAdapter(loader);
                };
            };
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
            return true;
        },
        addClick() {
            if (this.title === '') {
                return this.$message.error('标题不能为空')
            }
            if (this.imageUrl === '') {
                return this.$message.error('封面不能为空')
            }
            if (this.newsInfo === null || this.newsInfo === undefined) {
                addNews({
                    title: this.title,
                    url: this.url,
                    isRecommend: this.isRecommend ? 1 : 0,
                    image: this.imageUrl,
                    schoolid: this.schoolid,
                    sort: this.sort === '' ? 0 : +this.sort,
                    content: this.editorData,
                }).then(res => {
                    this.$emit('refreshList');
                    this.canncelClick()
                    this.$message.success('添加成功')
                })
            } else {
                editNews({
                    id: this.newsInfo.id,
                    title: this.title,
                    url: this.url,
                    isRecommend: this.isRecommend ? 1 : 0,
                    // likeNum: this.likeNum,
                    // shareNum: this.shareNum,
                    lookNum: this.lookNum,
                    // reviewNum: this.reviewNum,
                    sort: this.sort,
                    image: this.imageUrl,
                    content: this.editorData,
                }).then(res => {
                    this.$emit('refreshList');
                    this.canncelClick()
                    this.$emit('updateNewsInfo');
                    this.$message.success('修改成功')
                })
            }
        },
        canncelClick() {
            this.title = ''
            this.url = ''
            this.isRecommend = 0
            // this.likeNum = ''
            // this.shareNum = ''
            this.lookNum = ''
            // this.reviewNum = ''
            this.imageUrl = ''
            this.editorData = ''
            this.sort = ''
            this.isUrl = false
            this.$emit('updateNewsInfo');
            this.$emit('update:showDialog', false);
        },

    }
}
</script>
<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px !important;
    text-align: center;
}

.avatar {
    width: 78px;
    height: 78px;
    display: block;
}
</style>