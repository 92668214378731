import { baseURL } from "./const";

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
    this.uploaded = false;
  }

  // 进行上传
  upload() {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      this.loader.file.then((file) => {
        data.append("file", file); // 将文件添加到 FormData
        // 这里需要替换为你自己的上传接口
        fetch(`${baseURL}/api/common/upload`, {
          method: "POST",
          body: data,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // 根据实际情况设置 Authorization
          },
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.data) {
              resolve({ default: result.data }); // 返回图片 URL
            } else {
              reject("上传失败");
            }
          })
          .catch((error) => reject(error));
      });
    });
  }

  // 取消上传（可选）
  abort() {
    // 这里可以添加取消上传的逻辑
  }
}
