<template>
    <div class="page">
        <el-dialog title="新增轮播图" :visible.sync="showAddBannerDialog" :close-on-press-escape="false"
            :close-on-click-modal="false" :show-close="false">
            <el-form>
                <el-form-item label="标题" :label-width="formLabelWidth">
                    <el-input v-model="title" placeholder="请输入标题"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="formLabelWidth">
                    <el-input v-model="desc" placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item label="路由" :label-width="formLabelWidth">
                    <el-input v-model="router"
                        placeholder="请输入路由（type#:#target）type有：post、news、web，target：post、news为id, web为url"></el-input>
                </el-form-item>
                <el-form-item label="图片" :label-width="formLabelWidth">
                    <el-upload class="avatar-uploader" :action="baseURL + '/api/common/upload'" :headers="uploadHeaders"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="canncelClick">取 消</el-button>
                <el-button type="primary" @click="addBannerClick">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { addBanner } from '@/http/api'
import { baseURL } from '@/utils/const'
export default {
    data() {
        return {
            showAddBannerDialog: false,
            formLabelWidth: '120px',
            title: '',
            desc: '',
            router: '',
            imageUrl: '',
            baseURL: baseURL
        };
    },
    props: {
        schoolid: {
            type: Number
        },
        showDialog: {
            type: Boolean
        }
    },
    watch: {
        // 监听 schoolid 变化
        showDialog(newVal, oldVal) {
            this.showAddBannerDialog = newVal
        },
    },
    computed: {
        // 动态获取 headers
        uploadHeaders() {
            return {
                Authorization: `Bearer ${this.$store.state.token}`, // 从 Vuex 中动态获取 Token
            };
        },
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;

            // if (!isJPG) {
            //     this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
            return true;
        },
        addBannerClick() {
            if (this.imageUrl === '') {
                return this.$message.error('图片不能为空')
            }
            this.showAddBannerDialog = false;
            addBanner({
                title: this.title,
                description: this.desc,
                image: this.imageUrl,
                router: this.router,
                schoolid: this.schoolid
            }).then(res => {
                this.$emit('refreshList');
                this.$message.success('添加成功')
            })
        },
        canncelClick() {
            this.showAddBannerDialog = false;
            this.$emit('cancelAddDialog');
        }
    }
}
</script>
<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px !important;
    text-align: center;
}

.avatar {
    width: 78px;
    height: 78px;
    display: block;
}
</style>